import Box from "@vertikal/e-prospera.ui.box";
import ParseHtml from "@vertikal/e-prospera.ui.parse-html";
import Text from "@vertikal/e-prospera.ui.text";
import Breadcrumbs, { Breadcrumb } from "@vertikal/e-prospera.ui.breadcrumbs"
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { API } from "../api/api";
import {
  BreadCrumbCurrent,
  BreadCrumbLink,
  BreadCrumbsWrapper,
  TermsContent,
  TermsLayout,
  TermsWrapper,
} from "../styles/terms-of-service.styles";
import GlobalSettings from "../components/GlobalSettings";
import { useI18next } from "gatsby-plugin-react-i18next";
import MainMenu from "../components/MainMenu/MainMenu";

const PrivacyPolicyPage = () => {
  const [term, setTerm] = useState(null);
  const { language } = useI18next();

  useEffect(() => {
    async function getLegalStabilityDisclosure() {
      try {
        const req = await fetch(`${API}/terms-backend/public/term-definitions?code=LEGAL_STABILITY_DISCLOSURE_AND_DISCLAIMER`);
        const res = await req.json();
        if (!req.ok) {
          throw new Error(req.status);
        }
        setTerm(res[0]);

      } catch (error) {
        console.log("error", error);
      }
    }
    if (!term) {
      getLegalStabilityDisclosure();
    }
    return;
  });

  return (
    <>
      <GlobalSettings />
      <MainMenu>
      <BreadCrumbsWrapper>
        <Breadcrumbs>
          <Breadcrumb id="breadcrumb-home">
            <BreadCrumbLink href="/" color="gray" variant="p">
              <Trans>common.home</Trans>
            </BreadCrumbLink>
          </Breadcrumb>
          <BreadCrumbCurrent>
            <Trans>common.legal_stability_disclosure_and_disclaimer</Trans>
          </BreadCrumbCurrent>
        </Breadcrumbs>
      </BreadCrumbsWrapper>
      <TermsLayout>
        {!!term &&
          <TermsWrapper id="legal-stability-disclosure-and-disclaimer-content">
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              style={{ gap: "1rem" }}
            >
              <Text variant="h3">{term.name[language]}</Text>
              <Text variant="p">{term.versionNo}</Text>
              <TermsContent >
                <ParseHtml>
                  {term.content[language]}
                </ParseHtml>
              </TermsContent>
            </Box>
          </TermsWrapper>
        }
      </TermsLayout>
      </MainMenu>
    </>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;